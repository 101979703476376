import { Route } from '@/types/route'

export const diningHallTabRoutes: Route[] = [
  {
    path: 'menu-planner',
    name: 'menu-planner',
    meta: {
      title: 'Menu planner',
      tab: 'administration',
      permissions: ['menu.view_daymenu']
    },
    component: () => import('@/views/DiningHall/MenuPlanner/MenuPlanner.vue')
  },
  {
    path: 'meal-attendance',
    name: 'meal-attendance',
    meta: {
      title: 'Meal attendance',
      tab: 'administration',
      permissions: ['permissions.view_mealattendance']
    },
    component: () =>
      import('@/views/DiningHall/MealAttendance/MealAttendance.vue')
  }
]

export const diningHallRoutes = [
  {
    name: 'dining-hall',
    path: 'dining-hall',
    component: () => import('@/views/DiningHall/DiningHall.vue'),
    meta: {
      title: 'Dining hall',
      tab: 'administration'
    },
    children: diningHallTabRoutes
  }
]
