import { Route } from '@/types/route'

export const teachersTabRoutes: Route[] = [
  {
    name: 'teacher-list',
    path: 'artisans-list',
    component: () =>
      import('@/views/TeachersAdmin/TeachersList/TeachersListView.vue'),
    meta: {
      icon: 'user',
      title: 'Artisans',
      tab: 'administration',
      permissions: ['permissions.view_teachers_management']
    }
  },
  {
    name: 'users-list',
    path: 'users',
    component: () => import('@/views/TeachersAdmin/UserList/UserListView.vue'),
    meta: {
      icon: 'calendar',
      title: 'All users',
      tab: 'administration',
      permissions: ['permissions.view_users_management']
    }
  }
]

const teachersNotTabRoutes = [
  {
    name: 'add-user',
    path: 'add-user',
    component: () => import('@/views/TeachersAdmin/AddUser/AddUserView.vue'),
    meta: {
      title: 'User add',
      tab: 'administration',
      permissions: ['users.add_user']
    }
  },
  {
    name: 'user-details',
    path: 'users/:userId/details',
    component: () =>
      import('@/views/TeachersAdmin/UserDetails/UserDetailsView.vue'),
    meta: {
      title: 'Details',
      tab: 'administration',
      permissions: ['users.view_user']
    }
  },
  {
    name: 'user-edit',
    path: 'users/:userId/edit',
    component: () => import('@/views/TeachersAdmin/EditUser/EditUserView.vue'),
    meta: {
      title: 'User edit',
      tab: 'administration',
      permissions: ['users.change_user']
    }
  },
  {
    name: 'user-custom-schedule',
    path: 'users/:userId/custom-schedule',
    component: () =>
      import('@/views/TeachersAdmin/CustomSchedule/CustomScheduleView.vue'),
    meta: {
      title: 'Artisan custom schedule',
      tab: 'administration',
      permissions: ['teachers.change_teacher']
    }
  }
]

export const teachersRoutes = [
  {
    name: 'teachers',
    path: 'artisans',
    redirect: 'artisans/artisans-list',
    component: () => import('@/views/TeachersAdmin/TeachersAdminView.vue'),
    meta: {
      title: 'Artisans',
      tab: 'administration'
    },
    children: teachersTabRoutes
  },
  {
    path: 'artisans',
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      tab: 'administration'
    },
    children: teachersNotTabRoutes
  }
]
