import { getFirstPossibleRoute } from '@/router/utils/getFirstPossibleRoute'
import { attendanceRoutes } from './attendance'
import { disciplineRoutes } from './discipline'
import { healthRoutes } from './health'
import { roomManagementRoutes } from './room-management'
import { type Route } from '@/types/route'

export const boardingChildrenRoutes = [
  ...attendanceRoutes,
  ...healthRoutes,
  ...disciplineRoutes,
  ...roomManagementRoutes
] satisfies Route[]

export const boardingRoutes = [
  {
    path: '',
    name: 'boarding' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      tab: 'boarding',
      title: 'Boarding'
    },
    redirect: () => getFirstPossibleRoute(boardingChildrenRoutes),
    children: boardingChildrenRoutes
  }
] satisfies Route[]
